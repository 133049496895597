import React, { useEffect } from 'react';
import { Navbar } from '../components/navigation/Navbar';
import { Hero } from '../components/landing/Hero';
import { ProductShowcase } from '../components/landing/ProductShowcase';
import { FeatureCards } from '../components/landing/FeatureCards';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { Pricing } from '../components/landing/Pricing';
import { Contact } from '../components/landing/Contact';
import { Footer } from '../components/navigation/Footer';
import { useLocation } from 'react-router-dom';

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if we have a section to scroll to
    if (location.state?.scrollTo) {
      const sectionId = location.state.scrollTo;
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const headerOffset = sectionId === 'productshowcase' ? 100 : 
                           sectionId === 'featurecards' ? 10 : 
                           30; // for pricing
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [location]);

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[1300px] mx-auto">
            <Hero />
            <ProductShowcase />
            <FeatureCards />
            <Pricing />
            <Contact />
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default LandingPage;
