import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const SignUpPage = () => {
  const [step, setStep] = useState(1);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [fadeOut, setFadeOut] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  const handleNextStep = (e) => {
    e.preventDefault();
    setFadeOut(true);
    setTimeout(() => {
      setStep(prev => prev + 1);
      setFadeOut(false);
    }, 300);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setFadeOut(true);
    setTimeout(() => {
      setStep(prev => prev - 1);
      setFadeOut(false);
    }, 300);
  };

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[460px] mx-auto pt-24 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-6 shadow-xl border border-gray-200/20">
              {/* Back Arrow - Only show on steps 2 and 3 */}
              {step > 1 && (
                <button
                  onClick={handleBack}
                  className="absolute text-gray-600 hover:text-gray-800 transition-colors p-2 -ml-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}

              {/* Step 1: Initial Sign Up */}
              {step === 1 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Create Account</h1>
                    <p className="text-sm text-gray-600 mt-1">Join Lyra for Veterinary Practices</p>
                  </div>
                
                  <form className="space-y-4">
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="First name"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Last name"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Practice Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter your practice name"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter your email"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Password
                        </label>
                        <input
                          type="password"
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Create password"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-[#00B8D4] border-gray-300 rounded"
                      />
                      <label className="ml-2 text-xs text-gray-700">
                        I agree to the{' '}
                        <Link to="/terms" className="text-[#00B8D4] hover:text-[#008CA1]">
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link to="/privacy" className="text-[#00B8D4] hover:text-[#008CA1]">
                          Privacy Policy
                        </Link>
                      </label>
                    </div>

                    <button
                      onClick={handleNextStep}
                      className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2"
                    >
                      Continue
                    </button>
                  </form>

                  <div className="mt-4 text-center text-xs text-gray-600">
                    Already have an account?{' '}
                    <Link to="/login" className="text-[#00B8D4] hover:text-[#008CA1] font-medium">
                      Sign in
                    </Link>
                  </div>
                </div>
              )}

              {/* Step 2: Setup Process List */}
              {step === 2 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Setup Process</h1>
                    <p className="text-sm text-gray-600 mt-1">Here's what happens next</p>
                  </div>

                  <div className="space-y-8 mt-8">
                    {/* Step 1 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        1
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Complete Setup</h3>
                        <p className="text-sm text-gray-600">
                          Tell us about your clinic so we can integrate into your system.
                        </p>
                      </div>
                    </div>

                    {/* Step 2 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        2
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Verify Your Account</h3>
                        <p className="text-sm text-gray-600">
                          Don't forget to check your spam folder.
                        </p>
                      </div>
                    </div>

                    {/* Step 3 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        3
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Get Some Coffee</h3>
                        <p className="text-sm text-gray-600">
                          Take a break and allow us to reach out to your PIMs/EMR to finish your setup. (This takes about 24 hours.)
                        </p>
                      </div>
                    </div>

                    {/* Step 4 */}
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0 w-8 h-8 bg-[#00B8D4] rounded-full flex items-center justify-center text-white font-medium">
                        4
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">Blast Off!</h3>
                        <p className="text-sm text-gray-600">
                          After integration is complete log in at LyraVet.com and download the iOS app to start using the product.
                        </p>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={handleNextStep}
                    className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                             hover:bg-[#008CA1] transition-colors text-sm font-medium mt-8"
                  >
                    Continue
                  </button>
                </div>
              )}

              {/* Step 3: Demographics */}
              {step === 3 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Practice Location</h1>
                    <p className="text-sm text-gray-600 mt-1">Tell us where you're located</p>
                  </div>

                  <form className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Street Address
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter street address"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Suite/Unit (Optional)
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Suite or unit number"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Country
                        </label>
                        <CountryDropdown
                          value={country}
                          onChange={(val) => setCountry(val)}
                          classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                  focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                  placeholder-gray-400 transition-colors text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          State/Province
                        </label>
                        <RegionDropdown
                          country={country}
                          value={region}
                          onChange={(val) => setRegion(val)}
                          classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                  focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                  placeholder-gray-400 transition-colors text-sm"
                          disableWhenEmpty={true}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          City
                        </label>
                        <input
                          type="text"
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Enter city"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Postal Code
                        </label>
                        <input
                          type="text"
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Enter postal code"
                        />
                      </div>
                    </div>

                    <button
                      onClick={handleNextStep}
                      className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              )}

              {/* Step 4: Plan Selection */}
              {step === 4 && (
                <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                  <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-800">Select Your Plan</h1>
                    <p className="text-sm text-gray-600 mt-1">Choose the best option for your practice</p>
                  </div>

                  <form className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        How many veterinarians will be using the LyraVet?
                      </label>
                      <input
                        type="range"
                        min="1"
                        max="9"
                        className="w-full h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-[#00B8D4]"
                      />
                      <div className="flex justify-between text-xs text-gray-600 mt-1">
                        <span>1</span>
                        <span>9+</span>
                      </div>
                    </div>

                    <div className="flex justify-center space-x-4 py-2">
                      <button
                        type="button"
                        onClick={() => setBillingCycle('monthly')}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                  ${billingCycle === 'monthly' 
                                    ? 'bg-[#00B8D4] text-white' 
                                    : 'bg-gray-100 text-gray-600'}`}
                      >
                        Monthly
                      </button>
                      <button
                        type="button"
                        onClick={() => setBillingCycle('annual')}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                  ${billingCycle === 'annual' 
                                    ? 'bg-[#00B8D4] text-white' 
                                    : 'bg-gray-100 text-gray-600'}`}
                      >
                        Annual
                      </button>
                    </div>

                    <div className="text-center py-4">
                      <span className="text-3xl font-bold text-gray-800">$200</span>
                      <span className="text-gray-600">/month</span>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Promo Code (Optional)
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter promo code"
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full bg-[#00B8D4] text-white px-4 py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-2"
                    >
                      Complete Setup
                    </button>
                  </form>
                </div>
              )}
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default SignUpPage;
