import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const LoginPage = () => {
  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[400px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800">Welcome Back</h1>
                <p className="text-gray-600 mt-2">Sign in to your account</p>
              </div>
              
              <form className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                             focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                             placeholder-gray-400 transition-colors"
                    placeholder="Enter your email"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                             focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                             placeholder-gray-400 transition-colors"
                    placeholder="Enter your password"
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-[#00B8D4] border-gray-300 rounded"
                    />
                    <label className="ml-2 text-sm text-gray-700">Remember me</label>
                  </div>
                  <Link to="/forgot-password" className="text-sm text-[#00B8D4] hover:text-[#008CA1]">
                    Forgot password?
                  </Link>
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#00B8D4] text-white px-6 py-3 rounded-lg 
                           hover:bg-[#008CA1] transition-colors text-base font-medium"
                >
                  Sign in
                </button>
              </form>

              <div className="mt-6 text-center text-sm text-gray-600">
                Don't have an account?{' '}
                <Link to="/signup" className="text-[#00B8D4] hover:text-[#008CA1] font-medium">
                  Sign up
                </Link>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default LoginPage;
