import React from 'react';

const SpaceBackground = ({ children }) => {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="fixed inset-0" style={{ minHeight: '100vh' }}>
        {/* Base layer with subtle noise texture */}
        <div 
          className="absolute inset-0 opacity-5"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            backgroundSize: '200px 200px'
          }}
        />

        {/* Static gradient layers */}
        <div className="absolute inset-0">
          {/* Main nebula formations */}
          <div 
            className="absolute inset-0"
            style={{
              background: `
                radial-gradient(
                  ellipse at 70% 20%,
                  rgba(0,184,212,0.12) 0%,
                  rgba(26,35,126,0.08) 25%,
                  transparent 50%
                ),
                radial-gradient(
                  circle at 20% 30%,
                  rgba(26,35,126,0.1) 0%,
                  rgba(0,184,212,0.06) 30%,
                  transparent 60%
                ),
                radial-gradient(
                  ellipse at 30% 80%,
                  rgba(0,184,212,0.1) 0%,
                  rgba(26,35,126,0.07) 35%,
                  transparent 60%
                ),
                radial-gradient(
                  circle at 85% 40%,
                  rgba(26,35,126,0.08) 0%,
                  rgba(0,184,212,0.05) 40%,
                  transparent 70%
                )
              `
            }}
          />

          {/* Subtle diagonal streaks for depth */}
          <div 
            className="absolute inset-0"
            style={{
              background: `
                linear-gradient(
                  125deg,
                  transparent 0%,
                  rgba(0,184,212,0.03) 25%,
                  rgba(26,35,126,0.03) 50%,
                  transparent 75%
                ),
                linear-gradient(
                  -45deg,
                  transparent 0%,
                  rgba(26,35,126,0.03) 30%,
                  rgba(0,184,212,0.03) 60%,
                  transparent 85%
                )
              `
            }}
          />
        </div>
      </div>

      {/* Content */}
      <div className="relative">
        {children}
      </div>
    </div>
  );
};

export default SpaceBackground;