import React from 'react';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const PrivacyPolicyPage = () => {
  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[800px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              <h1 className="text-3xl font-bold text-gray-800 mb-8">Privacy Policy</h1>
              
              <div className="space-y-6 text-gray-700">
                <section>
                  <p className="mb-4">
                    LyraVet is a product of Vetreum Technologies, LLC. At Vetreum, we are committed to protecting the privacy and security of our users. 
                    This Privacy Policy outlines how we collect, use, and protect your information when 
                    you use our companion app for veterinary PIMs/EMRs. By using our app, you agree to 
                    the practices described in this policy.
                  </p>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">1. Information We Collect</h2>
                  <p className="mb-3">We only collect the following types of information from our users:</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li><span className="font-medium">Login Information:</span> This includes your username, password, and any other credentials necessary to access the app.</li>
                    <li><span className="font-medium">Billing Information:</span> This includes payment details, such as credit card numbers, billing addresses, and any other necessary payment-related information.</li>
                    <li><span className="font-medium">Software Functionality Data:</span> This includes any data necessary for the app to function properly and provide the intended services. This may include usage logs, technical data, and other operational information.</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">2. How We Use Your Information</h2>
                  <p className="mb-3">We use the information we collect solely for the following purposes:</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li><span className="font-medium">Login and Authentication:</span> To verify your identity and provide secure access to the app.</li>
                    <li><span className="font-medium">Billing and Payment Processing:</span> To process payments and manage your subscription or purchases.</li>
                    <li><span className="font-medium">Software Functionality:</span> To ensure the proper operation of the app and improve your user experience.</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">3. Data Sharing and Disclosure</h2>
                  <p className="mb-4">
                    Vetreum does not sell, rent, or share your personal information with third parties, except as necessary for the following:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li><span className="font-medium">Service Providers:</span> We may share your information with third-party service providers who assist us with billing, payment processing, and app functionality. These service providers are required to protect your information and use it only for the purposes for which it was shared.</li>
                    <li><span className="font-medium">Legal Compliance:</span> We may disclose your information if required to do so by law or in response to a valid legal request.</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">4. Data Security</h2>
                  <p className="mb-4">
                    We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure.
                  </p>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">5. Your Rights and Choices</h2>
                  <p className="mb-3">You have the right to:</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li><span className="font-medium">Access Your Information:</span> You can request access to the personal information we hold about you.</li>
                    <li><span className="font-medium">Update or Correct Information:</span> You can update or correct your personal information at any time.</li>
                    <li><span className="font-medium">Delete Your Information:</span> You can request the deletion of your personal information, subject to certain legal requirements.</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">6. Changes to This Privacy Policy</h2>
                  <p className="mb-4">
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this policy periodically to stay informed about how we protect your information.
                  </p>
                </section>

                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">7. Contact Us</h2>
                  <p className="mb-4">
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                  </p>
                  <p className="mb-2">Vetreum</p>
                  <p>
                    Email:{' '}
                    <a href="mailto:contact@vetreum.com" className="text-[#00B8D4] hover:text-[#008CA1]">
                      contact@vetreum.com
                    </a>
                  </p>
                </section>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default PrivacyPolicyPage;
