import React from 'react';
import { Clock, MessageSquare, FileText, ListChecks } from 'lucide-react';

export const FeatureCards = () => {
  const features = [
    {
      icon: <Clock className="h-8 w-8 text-cosmic-cyan" />,
      title: "Mission Brief",
      description: "Begin each day with confidence using AI-powered patient summaries. Get concise, clinically relevant information for all scheduled appointments, allowing you to focus on delivering excellent care without sifting through extensive records.",
      illustration: "/illustrations/daily-primer.svg",
      highlights: [
        "At-a-glance patient overviews",
        "Automated clinical summaries",
        "Prioritized health concerns"
      ]
    },
    {
      icon: <FileText className="h-8 w-8 text-cosmic-cyan" />,
      title: "Medical Scribe",
      description: "Transform your appointments into thorough medical notes effortlessly. Whether you prefer voice recording or quick bullet points, our AI scribe handles the heavy lifting, ensuring you spend less time on paperwork and more time with patients.",
      illustration: "/illustrations/medical-scribe.svg",
      highlights: [
        "Voice-to-SOAP conversion",
        "Bullet point expansion",
        "Automated medical formatting"
      ]
    },
    {
      icon: <MessageSquare className="h-8 w-8 text-cosmic-cyan" />,
      title: "Chat Support",
      description: "Unlock the full potential of your patient's medical history with AI-powered insights. Quickly retrieve specific information, track symptom progression, and refine care plans through natural conversation with your patient's complete history.",
      illustration: "/illustrations/patient-explorer.svg",
      highlights: [
        "Intelligent history analysis",
        "Lab result comparisons",
        "Treatment timeline tracking"
      ]
    },
    {
      icon: <ListChecks className="h-8 w-8 text-cosmic-cyan" />,
      title: "Task Checklist",
      description: "Make informed clinical decisions with our AI-powered research tool. Get comprehensive answers to any veterinary question, backed by cited sources from trusted academic resources.",
      illustration: "/illustrations/academic-search.svg",
      highlights: [
        "Cited research responses",
        "Verified medical sources",
        "Real-time clinical insights"
      ]
    }
  ];

  return (
    <div id="featurecards" className="max-w-7xl mx-auto px-4 py-24">
      <div className="space-y-32">
        {features.map((feature, index) => (
          <div 
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
            } items-center gap-12`}
          >
            {/* Content Side */}
            <div className="flex-1 space-y-6">
              <div className="inline-block p-3 bg-cosmic-cyan/10 rounded-2xl">
                {feature.icon}
              </div>
              <h2 className="text-4xl font-bold text-deep-space">
                {feature.title}
              </h2>
              <p className="text-xl text-gray-600">
                {feature.description}
              </p>
              <ul className="space-y-3">
                {feature.highlights.map((highlight, i) => (
                  <li key={i} className="flex items-center gap-3">
                    <div className="h-1.5 w-1.5 rounded-full bg-cosmic-cyan" />
                    <span className="text-gray-700">{highlight}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Illustration Side */}
            <div className="flex-1">
              <div className="aspect-square rounded-2xl bg-gradient-to-br from-cosmic-cyan-light/10 to-deep-space-light/10 p-8">
                {/* Replace with actual illustration component */}
                <div className="w-full h-full rounded-xl bg-white/50 border border-cosmic-cyan/20 flex items-center justify-center text-gray-400">
                  [Illustration Placeholder]
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
