import React from 'react';
import { Twitter, Linkedin, Facebook } from 'lucide-react';

export const Footer = () => {
  return (
    <footer className="border-t border-gray-400 bg-#8499A5">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Left Column: Logo and Email */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <img src="/BWtransparentlogo.png" alt="Lyra" className="h-8 w-8" />
              <span className="font-bold text-xl">Lyra</span>
            </div>
            <a 
              href="mailto:support@lyra.com" 
              className="text-gray-500 hover:text-[#00B8D4]"
            >
              support@lyra.vet
            </a>
          </div>

          {/* Middle Column: Social Media Links */}
          <div className="flex space-x-4 mt-4 md:mt-0 justify-center">
            <a 
              href="https://x.com/LyraVet" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-[#00B8D4]"
            >
              <Twitter className="h-5 w-5" />
            </a>
            <a 
              href="https://linkedin.com/company/lyravet" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-[#00B8D4]"
            >
              <Linkedin className="h-5 w-5" />
            </a>
            <a 
              href="https://www.facebook.com/profile.php?id=100094141754317"
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-[#00B8D4]"
            >
              <Facebook className="h-5 w-5" />
            </a>
          </div>

          {/* Right Column: Powered by, Terms, Privacy, Cookies */}
          <div className="flex flex-col space-y-4 text-right mt-8 md:mt-0">
            <p className="text-sm text-gray-500">Powered by Vetreum Technologies</p>
            <div className="flex space-x-6 justify-end">
              <a href="/terms" className="text-sm text-gray-500 hover:text-[#00B8D4]">Terms</a>
              <a href="/privacy" className="text-sm text-gray-500 hover:text-[#00B8D4]">Privacy</a>
              <a href="/cookies" className="text-sm text-gray-500 hover:text-[#00B8D4]">Cookies</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};