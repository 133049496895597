import React from 'react';

export const Contact = () => {
  return (
    <section className="py-24 max-w-3xl mx-auto px-4" id="contact">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold mb-4 text-deep-space">Get in Touch</h2>
        <p className="text-xl text-gray-500">
          Have questions? We're here to help. Reach out to our team.
        </p>
      </div>

      <form className="space-y-8">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              First Name
            </label>
            <input
              type="text"
              className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                         focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                         placeholder-gray-400 transition-colors"
              placeholder="Enter your first name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Last Name
            </label>
            <input
              type="text"
              className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                         focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                         placeholder-gray-400 transition-colors"
              placeholder="Enter your last name"
            />
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                       focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                       placeholder-gray-400 transition-colors"
            placeholder="Enter your email"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Subject
          </label>
          <select
            className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                       focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                       placeholder-gray-400 transition-colors"
          >
            <option value="">Select a topic</option>
            <option value="general">General Inquiry</option>
            <option value="demo">Request a Demo</option>
            <option value="pricing">Pricing Questions</option>
            <option value="support">Technical Support</option>
            <option value="other">Other</option>
          </select>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Message
          </label>
          <textarea
            rows="4"
            className="w-full px-4 py-3 rounded-lg bg-white/50 border border-gray-200 
                       focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                       placeholder-gray-400 transition-colors resize-none"
            placeholder="What's on your mind?"
          ></textarea>
        </div>

        <div className="text-center pt-4">
          <button
            type="submit"
            className="px-8 py-3 bg-[#00B8D4] hover:bg-[#00A5BE] text-white 
                     rounded-lg font-medium transition-colors inline-block
                     min-w-[200px]"
          >
            Send Message
          </button>
        </div>
      </form>
    </section>
  );
};
