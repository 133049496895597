import React from 'react';

export const Pricing = () => {
  const plans = [
    {
      name: 'Small Team',
      size: '(1-2 DVM)',
      price: 195,
      features: [
        'Daily Primers',
        'Patient Explorer',
        'Academic Search',
        'QuickSOAP',
        'Unlimited Team Use',
        'Email Support',
        '1-2 full time providers'
      ],
      valueProps: [
        'Perfect for solo practitioners',
        'Full feature access at an affordable price',
        'Includes support staff access'
      ]
    },
    {
      name: 'Mid-Size Team',
      size: '(3-4 DVM)',
      price: 395,
      features: [
        'Daily Primers',
        'Patient Explorer',
        'Academic Search',
        'QuickSOAP',
        'Unlimited Team Use',
        'Email Support',
        '3-4 full time providers'
      ],
      valueProps: [
        'Ideal for growing practices',
        'Seamless team collaboration',
        'Includes support staff access'
      ],
      popular: true
    },
    {
      name: 'Large Team',
      size: '(5-6 DVM)',
      price: 595,
      features: [
        'Daily Primers',
        'Patient Explorer',
        'Academic Search',
        'QuickSOAP',
        'Unlimited Team Use',
        'Priority Email Support',
        '5-6 full time providers'
      ],
      valueProps: [
        'Built for busy multi-doctor practices',
        'Priority support access',
        'Includes support staff access'
      ]
    }
  ];

  return (
    <section className="py-16 max-w-7xl mx-auto" id="pricing">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold mb-4 text-deep-space">Simple, Transparent Pricing</h2>
        <p className="text-xl text-gray-600">Choose the plan that fits your team</p>
      </div>
      
      <div className="grid md:grid-cols-3 gap-6 px-4">
        {plans.map((plan) => (
          <div 
            key={plan.name} 
            className={`
              bg-white rounded-xl p-6  
              shadow-sm border border-gray-100
              hover:border-[#00B8D4]/30 transition-all
              ${plan.popular ? 'ring-2 ring-[#00B8D4] relative scale-[1.02]' : ''}
            `}
          >
            {plan.popular && (
              <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                <span className="bg-[#00B8D4] text-white text-sm font-medium px-3 py-1 rounded-full">
                  Most Popular
                </span>
              </div>
            )}
            <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
            <p className="text-gray-500 mb-4">{plan.size}</p>
            <div className="text-3xl font-bold mb-6">
              ${plan.price} <span className="text-lg font-normal text-gray-600">/month</span>
            </div>
            <p className="text-red-400 text-sm mb-6">Discounted annual rate available</p>
            
            {/* Value Props */}
            <div className="mb-6 bg-[#00B8D4]/5 rounded-lg p-4">
              {plan.valueProps.map((prop, index) => (
                <p key={index} className="text-sm text-gray-700 mb-2 flex items-center">
                  <span className="text-[#00B8D4] mr-2">★</span>
                  {prop}
                </p>
              ))}
            </div>

            {/* Features */}
            <ul className="space-y-3 mb-8">
              {plan.features.map((feature) => (
                <li key={feature} className="flex items-center text-gray-700 text-sm">
                  <svg className="w-4 h-4 mr-2 text-[#00B8D4]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            
            <a 
              href="https://calendly.com/admin-lyravet/demo"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full py-2.5 px-4 bg-[#00B8D4] hover:bg-[#00A5BE] text-white rounded-lg font-medium transition-colors text-sm inline-block text-center"
            >
              Learn More
            </a>
          </div>
        ))}
      </div>

      <div className="text-center mt-16">
        <h3 className="text-2xl font-bold mb-4">Have a team of 7+ DVMs?</h3>
        <p className="text-lg text-gray-600">
          Reach out to us directly for enterprise pricing at{' '}
          <a href="mailto:enterprise@vetreum.com" className="text-[#00B8D4] hover:text-[#00A5BE]">
            enterprise@vetreum.com
          </a>
        </p>
      </div>
    </section>
  );
};
