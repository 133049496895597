import React from 'react';

export const Illustration = () => {
  return (
    <div className="relative">
      <img 
        src="/illustration.png" 
        alt="Illustration" 
        className="w-full h-auto"
      />
    </div>
  );
};