import React from 'react';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const TermsPage = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[800px] mx-auto pt-32 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-8 shadow-xl border border-gray-200/20">
              <h1 className="text-3xl font-bold text-gray-800 mb-4">Terms of Service</h1>
              <p className="text-gray-600 mb-8">
                <strong>Effective Date:</strong> {currentDate}
              </p>

              <div className="space-y-8 text-gray-700">
                {/* Introduction */}
                <section>
                  <p className="mb-6">
                    Please read these Terms of Service ("Agreement") carefully before using the services 
                    offered by Vetreum Technologies ("Company," "we," "us," or "our"). By accessing, 
                    registering for, or using our software-as-a-service platform and related services 
                    ("Services"), you ("User" or "you") agree to be bound by this Agreement. If you do 
                    not agree to these terms, you must not use the Services.
                  </p>
                </section>

                {/* Section 1 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">1. Description of Services</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">1.1 Services Provided</h3>
                      <p>
                        Vetreum Technologies provides a suite of software tools and applications, including 
                        but not limited to a virtual assistant powered by a large language model ("LLM"), 
                        designed to support veterinary professionals in their practice by generating summaries, 
                        medical notes, and offering veterinary-specific chatbots that may reference patient 
                        information.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">1.2 Service Limitations</h3>
                      <p>
                        The Services are intended to supplement, not replace, the clinical judgment of 
                        qualified veterinary professionals. The accuracy of information provided by the 
                        Services is not guaranteed, and reliance on such information is at your own risk.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 2 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Eligibility and Registration</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">2.1 Professional Use Only</h3>
                      <p>
                        The Services are available exclusively to trained veterinary personnel, including 
                        licensed veterinarians, veterinary technicians, and other qualified veterinary 
                        professionals. By using the Services, you represent and warrant that you are a 
                        trained veterinary professional.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">2.2 Account Registration</h3>
                      <p>
                        To access the Services, you must register an account and provide accurate and 
                        complete information, including your name, email address, clinic details, and 
                        physical address. You are responsible for maintaining the confidentiality of your 
                        account credentials and for all activities that occur under your account.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">2.3 Consent to Electronic Communications</h3>
                      <p>
                        By registering, you consent to receive electronic communications from us related 
                        to your account and use of the Services.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 3 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">3. User Responsibilities</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">3.1 Professional Judgment</h3>
                      <p>
                        You agree to use the Services responsibly and within the scope intended to support 
                        veterinary practice. You must not rely solely on the Services for making clinical 
                        decisions and should always use your professional judgment.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">3.2 User Responsibility for Decisions and Actions</h3>
                      <p>
                        You are solely responsible for any decisions, actions, or omissions made based on 
                        the output generated by the Services, including all medical diagnoses, treatments, 
                        and any other professional activities.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">3.3 Compliance with Laws</h3>
                      <p>
                        You agree to comply with all applicable laws and regulations in connection with 
                        your use of the Services, including but not limited to those related to the 
                        protection of personal and patient information.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 4 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">4. Patient Information and Data Privacy</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">4.1 Compliance with Data Protection Laws</h3>
                      <p>
                        You represent and warrant that you have obtained all necessary consents and 
                        authorizations to input patient information into the Services. You agree to comply 
                        with all applicable data protection and privacy laws, including but not limited to 
                        confidentiality obligations.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">4.2 Data Security</h3>
                      <p>
                        We implement reasonable security measures to protect patient information. However, 
                        we cannot guarantee absolute security, and you acknowledge and accept the inherent 
                        risks of transmitting data over the internet.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">4.3 Privacy Policy</h3>
                      <p>
                        Our collection, use, and disclosure of personal information are governed by our 
                        Privacy Policy, which is available at www.vetreum.com.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 5 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Artificial Intelligence Output</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">5.1 No Guarantee of Accuracy</h3>
                      <p>
                        The AI-generated content provided by the Services is for informational purposes 
                        only and is not guaranteed to be accurate, complete, or up-to-date.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">5.2 Limitation of Liability for AI Output</h3>
                      <p>
                        We shall not be liable for any errors or omissions in the AI output or for any 
                        actions taken in reliance thereon. You acknowledge that the AI may produce 
                        incorrect or inappropriate content, and you agree to independently verify any 
                        information before relying on it.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">5.3 Adaptive Service Changes</h3>
                      <p>
                        The specific LLM powering the Services may change based on technological 
                        advancements and resource availability. We will make reasonable efforts to inform 
                        you of significant changes that may affect functionality or performance.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 6 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">6. Subscription Terms, Fees, and Payment</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">6.1 Subscription Plans</h3>
                      <p>
                        Access to certain features of the Services may require a subscription. Fees are 
                        based on the selected subscription plan and are due as stated at the time of 
                        subscription or renewal.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">6.2 Automatic Renewal</h3>
                      <p>
                        Subscriptions automatically renew under the previously agreed terms unless canceled 
                        by you prior to the renewal date. You are responsible for managing your 
                        subscription preferences.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">6.3 Payment Processing</h3>
                      <p>
                        Payment information will be processed securely by third-party payment processors. 
                        We do not store your payment information on our servers.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 7 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">7. User Conduct</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">7.1 Acceptable Use</h3>
                      <p>
                        You agree to use the Services solely for lawful purposes and in accordance with 
                        this Agreement.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">7.2 Prohibited Conduct</h3>
                      <ul className="list-disc pl-6 space-y-2">
                        <li>Use the Services for any illegal or unauthorized purpose.</li>
                        <li>Input or transmit any material that is unlawful, defamatory, obscene, or otherwise objectionable.</li>
                        <li>Attempt to interfere with or disrupt the Services or servers.</li>
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">7.3 Misuse of Services</h3>
                      <p>
                        Misuse of the Services may result in immediate termination of your account without 
                        prior notice.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 8 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">8. Intellectual Property Rights</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">8.1 Ownership</h3>
                      <p>
                        All rights, titles, and interests in and to the Services, including all 
                        intellectual property rights, are and will remain the exclusive property of 
                        Vetreum Technologies and its licensors.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">8.2 License Grant</h3>
                      <p>
                        We grant you a limited, non-exclusive, non-transferable, revocable license to 
                        access and use the Services for their intended purpose in accordance with this 
                        Agreement.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">8.3 User Content</h3>
                      <p>
                        By inputting data into the Services, you grant us a non-exclusive, worldwide, 
                        royalty-free license to use, reproduce, and process the data solely for the 
                        purpose of providing the Services.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 9 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">9. Disclaimers and Warranties</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">9.1 Service Provided "As Is"</h3>
                      <p>
                        The Services are provided on an "as is" and "as available" basis. We make no 
                        warranties, express or implied, regarding the Services, including but not limited 
                        to warranties of merchantability, fitness for a particular purpose, or 
                        non-infringement.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">9.2 No Medical Advice</h3>
                      <p>
                        The Services do not provide medical advice. The AI-generated content is not a 
                        substitute for professional judgment. Always use your clinical judgment when 
                        treating patients and consult professional veterinary guidelines as necessary.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 10 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">10. Limitation of Liability</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">10.1 Exclusion of Certain Damages</h3>
                      <p>
                        To the maximum extent permitted by law, in no event shall Vetreum Technologies be 
                        liable for any indirect, incidental, special, consequential, or punitive damages, 
                        including but not limited to loss of profits, data, use, goodwill, or other 
                        intangible losses.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">10.2 Cap on Liability</h3>
                      <p>
                        Our total liability arising out of or in connection with this Agreement or the use 
                        of the Services shall not exceed the amount you have paid to us for the Services 
                        in the twelve (12) months immediately preceding the event giving rise to the claim.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 11 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">11. Indemnification</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">11.1 User Indemnity</h3>
                      <p>
                        You agree to indemnify, defend, and hold harmless Vetreum Technologies, its 
                        affiliates, officers, directors, employees, and agents from and against any claims, 
                        liabilities, damages, losses, and expenses, including reasonable attorney's fees, 
                        arising out of or in any way connected with your access to or use of the Services, 
                        your violation of this Agreement, or your infringement of any intellectual property 
                        or other rights of any person or entity.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 12 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">12. Modifications to the Agreement</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">12.1 Right to Modify</h3>
                      <p>
                        We reserve the right to modify this Agreement at any time. Any changes will be 
                        effective immediately upon posting the revised Agreement.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">12.2 Notice of Changes</h3>
                      <p>
                        We will notify you of any material changes to the Agreement by posting a notice on 
                        our website, via email, or by other appropriate means.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">12.3 Acceptance of Modified Terms</h3>
                      <p>
                        Your continued use of the Services after any such changes constitutes your 
                        acceptance of the new terms.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 13 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">13. Changes to Company Name</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">13.1 Successor Entities</h3>
                      <p>
                        References to "Vetreum Technologies" or the "Company" shall include any successor 
                        or affiliated entities. If the Company undergoes a name change or rebranding, this 
                        Agreement shall remain in effect with respect to the new entity.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 14 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">14. Termination</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">14.1 Termination by User</h3>
                      <p>
                        You may terminate your use of the Services at any time by discontinuing access to 
                        the Services.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">14.2 Termination by Company</h3>
                      <p>
                        We may suspend or terminate your access to the Services immediately, without prior 
                        notice or liability, for any reason whatsoever, including but not limited to your 
                        breach of this Agreement.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">14.3 Effect of Termination</h3>
                      <p>
                        Upon termination, your right to use the Services will immediately cease. All 
                        provisions of this Agreement which by their nature should survive termination 
                        shall survive, including but not limited to ownership provisions, warranty 
                        disclaimers, indemnity, and limitations of liability.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 15 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">15. Dispute Resolution</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">15.1 Governing Law</h3>
                      <p>
                        This Agreement shall be governed by and construed in accordance with the laws of 
                        Wisconsin, without regard to its conflict of law provisions.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">15.2 Arbitration</h3>
                      <p>
                        Any disputes arising out of or relating to this Agreement or the Services shall be 
                        finally settled by binding arbitration conducted in Madison, Wisconsin, in 
                        accordance with the rules of the American Arbitration Association. The arbitration 
                        shall be conducted before a single arbitrator, and judgment upon the award 
                        rendered by the arbitrator may be entered in any court having jurisdiction thereof.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">15.3 Waiver of Class Actions</h3>
                      <p>
                        You agree that any dispute resolution proceedings will be conducted only on an 
                        individual basis and not in a class, consolidated, or representative action.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 16 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">16. Severability</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">16.1 Severability Clause</h3>
                      <p>
                        If any provision of this Agreement is found to be invalid or unenforceable by a 
                        court of competent jurisdiction, the remaining provisions shall remain in full 
                        force and effect.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 17 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">17. Entire Agreement</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">17.1 Integration</h3>
                      <p>
                        This Agreement, along with our Privacy Policy, constitutes the entire agreement 
                        between you and Vetreum Technologies regarding the Services and supersedes all 
                        prior agreements, whether written or oral, relating to the subject matter herein.
                      </p>
                    </div>
                  </div>
                </section>

                {/* Section 18 */}
                <section>
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">18. Contact Information</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-2">18.1 How to Contact Us</h3>
                      <p className="mb-2">If you have any questions about this Agreement, please contact us at:</p>
                      <p>
                        Email:{' '}
                        <a href="mailto:contact@vetreum.com" className="text-[#00B8D4] hover:text-[#008CA1]">
                          contact@vetreum.com
                        </a>
                      </p>
                    </div>
                  </div>
                </section>

                <p className="mt-8 text-gray-600">
                  By accessing or using the Services, you acknowledge that you have read, understood, 
                  and agree to be bound by this Agreement.
                </p>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default TermsPage;
