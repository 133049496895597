import React, { useEffect, useState, useMemo } from 'react';

const CelestialDecorations = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  useEffect(() => {
    let ticking = false;
    
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const position = window.scrollY;
          if (position <= 800) {
            setScrollPosition(position);
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { scale, opacity } = useMemo(() => ({
    scale: Math.max(1 - (scrollPosition * 0.0008), 0.3),
    opacity: Math.max(0.2 - (scrollPosition * 0.0004), 0)
  }), [scrollPosition]);

  const baseTransitionStyle = useMemo(() => ({
    transition: 'transform 0.2s, opacity 0.2s',
    transformOrigin: 'center center'
  }), []);

  const commonStyles = useMemo(() => ({
    ...baseTransitionStyle,
    willChange: 'transform, opacity'
  }), [baseTransitionStyle]);

  const styles = useMemo(() => ({
    icePlanet: {
      ...commonStyles,
      transform: `scale(${scale * 0.6})`,
      opacity: opacity * 0.6
    },
    terrestrial: {
      ...commonStyles,
      transform: `scale(${scale * 0.8})`,
      opacity: opacity * 0.6
    },
    gasGiant: {
      ...commonStyles,
      transform: `scale(${scale * 0.6})`,
      opacity: opacity * 0.6
    },
    constellation: {
      ...commonStyles,
      transform: `scale(${Math.min(scale, 1)})`,
      opacity: opacity * 1.0
    },
    dogConstellation: {
      ...commonStyles,
      transform: `scale(${Math.min(scale, 1)})`,
      opacity: opacity * 2.0
    }
  }), [commonStyles, scale, opacity]);

  if (opacity === 0) {
    return null;
  }

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      {/* Ice Planet with Rings */}
      <div className="absolute top-16 right-8" style={styles.icePlanet}>
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="70" cy="70" r="25" fill="#1E88E5" fillOpacity="0.9" />
          <circle cx="85" cy="65" r="10" fill="#1565C0" fillOpacity="0.8" />
          <circle cx="50" cy="75" r="8" fill="#1565C0" fillOpacity="0.8" />
          <ellipse cx="70" cy="70" rx="45" ry="4" transform="rotate(-25 70 70)" stroke="#1565C0" strokeWidth="5" strokeOpacity="0.9" />
          <ellipse cx="70" cy="70" rx="42" ry="3" transform="rotate(-25 70 70)" stroke="#1E88E5" strokeWidth="4" strokeOpacity="0.8" />
          <ellipse cx="70" cy="70" rx="48" ry="4" transform="rotate(-25 70 70)" stroke="#1565C0" strokeWidth="4" strokeOpacity="0.7" />
        </svg>
      </div>

      {/* Enhanced Terrestrial Planet */}
      <div className="absolute bottom-64 left-16" style={styles.terrestrial}>
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="45" cy="45" r="25" fill="#1A237E" fillOpacity="0.7" />
          <path d="M25 45 Q 45 35, 65 45" stroke="#283593" strokeWidth="4" strokeOpacity="0.8" />
          <path d="M30 55 Q 45 48, 60 55" stroke="#283593" strokeWidth="3" strokeOpacity="0.7" />
          <circle cx="55" cy="35" r="6" fill="#283593" fillOpacity="0.8" stroke="#1A237E" strokeWidth="1" />
          <circle cx="35" cy="40" r="4" fill="#283593" fillOpacity="0.8" stroke="#1A237E" strokeWidth="1" />
          <circle cx="50" cy="50" r="5" fill="#283593" fillOpacity="0.8" stroke="#1A237E" strokeWidth="1" />
          <circle cx="45" cy="45" r="26" stroke="#00B8D4" strokeWidth="1.5" strokeOpacity="0.4" />
          <circle cx="45" cy="45" r="27" stroke="#00B8D4" strokeWidth="0.5" strokeOpacity="0.3" />
          <circle cx="35" cy="35" r="8" fill="#3949AB" fillOpacity="0.3" />
        </svg>
      </div>
      
      {/* Gas Giant Planet */}
      <div className="absolute bottom-32 right-12" style={styles.gasGiant}>
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="60" cy="60" r="25" fill="#1A237E" fillOpacity="0.8" />
          <path d="M35 60 Q 60 45, 85 60" stroke="#304FFE" strokeWidth="4" strokeOpacity="0.6" />
          <path d="M35 70 Q 60 55, 85 70" stroke="#304FFE" strokeWidth="3" strokeOpacity="0.5" />
          <circle cx="75" cy="55" r="10" fill="#00B8D4" fillOpacity="0.4" />
          <ellipse cx="60" cy="60" rx="40" ry="6" transform="rotate(-15 60 60)" stroke="#00B8D4" strokeWidth="2" strokeOpacity="0.6" />
          <ellipse cx="60" cy="60" rx="37" ry="5" transform="rotate(-15 60 60)" stroke="#1A237E" strokeWidth="1" strokeOpacity="0.5" />
          <circle cx="95" cy="45" r="5" fill="#1A237E" fillOpacity="0.8" />
        </svg>
      </div>

      {/* Dog Constellation - Now in original Cat position */}
      <div className="absolute top-1/3 right-16" style={styles.dogConstellation}>
        <svg width="140" height="120" viewBox="0 0 140 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="40" r="1.5" fill="#00B8D4" fillOpacity="0.95" />
          <circle cx="45" cy="35" r="2" fill="#00B8D4" fillOpacity="0.95" />
          <circle cx="70" cy="45" r="1.5" fill="#00B8D4" fillOpacity="0.95" />
          <circle cx="85" cy="70" r="2" fill="#00B8D4" fillOpacity="0.95" />
          <circle cx="60" cy="60" r="1.5" fill="#00B8D4" fillOpacity="0.95" />
          <path d="M20 40L45 35L70 45L85 70M70 45L60 60" 
                stroke="#00B8D4" 
                strokeWidth="0.75"
                strokeOpacity="0.6" />
        </svg>
      </div>

      {/* Cat Constellation - Now in original Dog position */}
      <div className="absolute top-1/4 left-20" style={styles.constellation}>
        <svg width="120" height="140" viewBox="0 0 120 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="40" cy="20" r="1.5" fill="#1A237E" fillOpacity="0.95" />
          <circle cx="60" cy="30" r="2" fill="#1A237E" fillOpacity="0.95" />
          <circle cx="80" cy="25" r="1.5" fill="#1A237E" fillOpacity="0.95" />
          <circle cx="70" cy="45" r="2" fill="#1A237E" fillOpacity="0.95" />
          <circle cx="50" cy="50" r="1.5" fill="#1A237E" fillOpacity="0.95" />
          <path d="M40 20L60 30L80 25M60 30L70 45L50 50" 
                stroke="#1A237E" 
                strokeWidth="0.75"
                strokeOpacity="0.6" />
        </svg>
      </div>
    </div>
  );
};

export default React.memo(CelestialDecorations);